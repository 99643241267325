import { MenuBook } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import editMenuImage from '../../../assets/images/edit-menu.png';

const MenuBox = () => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            flexGrow={1}
            maxWidth={242}
            border={"1px solid"}
            borderRadius={8}
            borderColor={theme.palette.outline.main}
            bgcolor={theme.palette.secondaryContainer.main}
            boxSizing="border-box"
            sx={{
                aspectRatio: '1/1'
            }}
        >
            <Typography
                variant="subtitle2"
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '140%',
                    color: theme.palette.text.primary,
                }}

                paddingTop="30px"
                paddingX="30px"
                width={'100%'}
            >
                Editer mon menu
            </Typography>
            <Box
                position={"relative"}
                bottom={0}
                right={0}
                display="flex"
                alignItems="right"
                justifyContent="flex-end"
                marginTop="auto"
                width={'100%'}
                p={1}
            >
                <Box component={'img'} src={editMenuImage} height={100} />
            </Box>
        </Box>
    );
};

export default MenuBox;
