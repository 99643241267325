import React from 'react';
import { Box } from '@mui/material';
import BottomNavbar from './BottomNavbar';
import { OrdersProvider } from '../context/OrdersContext';

const MainLayout = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    paddingBottom: '80px', // To ensure content is not hidden behind the navbar
                }}
            >
                {children}
            </Box>
            <OrdersProvider>
                <BottomNavbar />
            </OrdersProvider>
        </Box>
    );
};

export default MainLayout;
