import React from 'react';
import { Box, Typography, ButtonBase } from '@mui/material';
import theme from '../theme';

const LongButton = ({ type, variant, icon: Icon, label, onClick, disabled, sx }) => {
  const isPrimary = variant === 'primary';
  const isOutlined = variant === 'outlined';
  const isInverseOutlined = variant === 'inverse-outlined';
  const isError = variant === 'error';
  const isDisabled = variant === 'disabled';

  return (
    <ButtonBase
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '48px',
        backgroundColor: isPrimary ? theme.palette.primary.main : isInverseOutlined ? 'white' : isError ? 'white' : 'transparent',
        borderRadius: '100px',
        border: '1px solid',
        borderColor: isPrimary
          ? theme.palette.primary.contrastText
          : isOutlined
            ? theme.palette.primary.main
            : isInverseOutlined
              ? 'white'
              : isError
                ? theme.palette.error.main
                : theme.palette.text.secondary,
        padding: 0,
        gap: '8px',
        color: isPrimary
          ? theme.palette.primary.contrastText
          : isOutlined
            ? theme.palette.primary.main
            : isInverseOutlined
              ? 'white'
              : isError
                ? theme.palette.error.main
                : theme.palette.text.secondary,
        opacity: isDisabled ? 0.88 : 1,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ...sx
      }}
    >
      {Icon && (
        <Icon
          sx={{
            width: '18px',
            height: '18px',
            color: isPrimary
              ? theme.palette.primary.contrastText
              : isOutlined
                ? theme.palette.primary.main
                : isOutlined
                  ? theme.palette.primary.main
                  : isError
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
            opacity: isDisabled ? 0.88 : 1,
          }}
        />
      )}
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.1px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          color: isPrimary
            ? theme.palette.primary.contrastText
            : isOutlined
              ? theme.palette.primary.main
              : isInverseOutlined
                ? theme.palette.primary.main
                : isError
                  ? theme.palette.error.main
                  : theme.palette.text.secondary,
          opacity: isDisabled ? 0.88 : 1,
        }}
      >
        {label}
      </Typography>
    </ButtonBase>
  );
};

export default LongButton;
