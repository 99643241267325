import React, { useState, useEffect, useContext } from 'react';
import { Container, Box, Typography, Grid } from '@mui/material';
import OrderCard from './components/OrderCard';
import LongButton from '../../shared-components/LongButton';
import { useNavigate } from 'react-router-dom';
import ArchiveOutlined from '@mui/icons-material/ArchiveOutlined';
import '../../css/loader.css';
import { useTheme } from '@mui/material/styles';
import { Topbar } from '../../shared-components/Topbar';
import useOrder from '../../hooks/useOrder';
import OrderTypeFilterChips from './components/OrderTypeFilterChips';
import { AddOutlined } from '@mui/icons-material';
import { OrdersContext } from '../../context/OrdersContext';
import "./css/animation.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useAutoAnimate } from '@formkit/auto-animate/react';
import OrderStatusFilterChips from './components/OrderStatusFilterChips';

const Orders2 = () => {
  const theme = useTheme();
  const { createOrder } = useContext(OrdersContext);
  const { orders } = useOrder();
  const navigate = useNavigate();
  const [parent] = useAutoAnimate();
  // State for type filters
  const [selectedTypeChips, setSelectedTypeChips] = useState({
    IN_TABLE: true,
    TAKE_OUT: true,
  });

  // State for status filters
  const [selectedStatusChips, setSelectedStatusChips] = useState({
    CREATED: true,
    CONFIRMED: true,
    VALIDATED: true,
    CLOSED: true,
  });

  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    const applyFilter = () => {
      const filtered = orders.filter(order =>
        selectedTypeChips[order.order.orderType] && selectedStatusChips[order.order.status]
      );
      setFilteredOrders(filtered);
    };

    applyFilter();
  }, [orders, selectedTypeChips, selectedStatusChips]);

  // Handle type filter change
  const handleTypeFilterChange = (newSelectedChips) => {
    setSelectedTypeChips(newSelectedChips);
  };

  // Handle status filter change
  const handleStatusFilterChange = (newSelectedChips) => {
    setSelectedStatusChips(newSelectedChips);
  };

  const handleCreateNewOrderClick = async () => {
    const newOrder = await createOrder('TAKE_OUT');
    navigate('/orders/edit', { state: { currentOrder: newOrder } });
  };

  useEffect(() => {
    console.log('orders', orders);
  }, [orders]);


  if (!orders) {
    return <div className='loader'></div>;
  }

  return (
    <Container maxWidth="sm" style={{ padding: '16px', fontFamily: '"Inter", sans-serif' }}>
      <Topbar name="Mes commandes" showBackButton={false} />
      <Container
        maxWidth="sm"
        sx={{
          padding: theme.spacing(2),
          height: '90%',
          fontFamily: theme.typography.fontFamily,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <OrderTypeFilterChips onFilterChange={handleTypeFilterChange} />
        <OrderStatusFilterChips onFilterChange={handleStatusFilterChange} />

        {filteredOrders.length === 0 ? (
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: theme.spacing(2),
                border: `1px solid ${theme.palette.outline.main}`,
                borderRadius: '12px',
                backgroundColor: theme.palette.container.primary.highest,
                height: '480px'
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
                Aucune commande en cours.
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: theme.spacing(2), color: theme.palette.text.secondary }}>
                Vos bons de commande s’afficheront sur cet écran dès qu’un client l’aura envoyée depuis son téléphone.
              </Typography>
              <LongButton
                variant="primary"
                icon={AddOutlined}
                label="Nouvelle commande à emporter"
                sx={{ marginBottom: theme.spacing(2) }}
                onClick={handleCreateNewOrderClick}
              />
              <LongButton
                variant="outlined"
                icon={ArchiveOutlined}
                label="Voir les commandes archivées"
                sx={{ marginBottom: theme.spacing(2) }}
                onClick={() => navigate('/orders/archived')}
              />
            </Box>
          </Box>
        ) : (
          <Grid container spacing={0.5} gap={2} my={3} ref={parent}>
            {filteredOrders.map((order, index) => (
              <OrderCard
                key={index}
                order={order.order}
                table={order.table}
              />
            ))}
          </Grid>
        )}
      </Container>
    </Container>
  );
};

export default Orders2;
