import { Client } from '@stomp/stompjs';
import React, { createContext, useContext, useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import useApi from '../api';
import useMenu from '../hooks/useMenu';
import { AppContext } from './AppContext';
import { AuthContext } from './AuthContext';

const OrdersContext = createContext();

const OrdersProvider = ({ children }) => {
    const { eateryId } = useContext(AuthContext);
    const { menu, getFoodItemById } = useMenu();
    const { tables } = useContext(AppContext);
    const { openOrderApi, closeOrderApi, updateOrderApi, deleteOrderApi, validateOrderApi, getCachedOrdersApi, createOrderApi } = useApi();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState(null);

    useEffect(() => {
        const fetchLatestOrders = async () => {
            try {
                const latestOrders = await getCachedOrdersApi();
                setOrders(latestOrders);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching the latest cached orders:', error);
                setLoading(false);
            }
        };

        fetchLatestOrders();

        // WebSocket setup
        const socket = new SockJS('http://localhost:8080/ws');
        const stompClient = new Client({
            webSocketFactory: () => socket,
            debug: (str) => console.log(str),
            onConnect: () => {
                stompClient.subscribe(`/topic/orders/${eateryId}/admin`, (message) => {
                    console.log("received new order from ws", message.body);
                    const newOrders = JSON.parse(message.body);
                    setOrders(newOrders);
                });
            },
            onStompError: (frame) => {
                console.error('WebSocket error:', frame.headers['message']);
                console.error('Additional details:', frame.body);
            }
        });

        stompClient.activate();
        setClient(stompClient);

        return () => {
            stompClient.deactivate();
        };
    }, [eateryId]);

    // Function to get order by tableId
    const getOrderById = (id) => {
        return orders.find(order => order.order.id === id);
    };

    const getOrderByTableId = (tableId) => {
        return orders.find(order => order.tableId === tableId);
    };

    const createOrder = async (orderType, tableId) => {
        let newOrder = {
            eateryId,
            orderType,
            tableId,
            creator: 'ADMIN',
            people: [],
            foodItems: [],
        };

        newOrder = await createOrderApi(newOrder);

        return newOrder;
    }

    const validateOrder = async (orderId) => {
        try {
            await validateOrderApi(eateryId, orderId);

            // Update the order status in the state
            setOrders(prevOrders => prevOrders.map(order =>
                order.order.id === orderId ? { ...order, order: { ...order.order, status: 'VALIDATED' } } : order
            ));
        } catch (error) {
            console.error('Failed to validate order', error);
            return error;
        }
    };


    const openOrder = async (orderId) => {
        try {
            await openOrderApi(eateryId, orderId);
            setOrders(prevOrders => prevOrders.map(order =>
                order.order.id === orderId ? { ...order, order: { ...order.order, status: 'CREATED' } } : order
            ));
        } catch (error) {
            console.error('Failed to open order', error);
            return error;
        }
    };

    const closeOrder = async (orderId) => {
        try {
            await closeOrderApi(eateryId, orderId);
            setOrders(prevOrders => prevOrders.map(order =>
                order.order.id === orderId ? { ...order, order: { ...order.order, status: 'CLOSED' } } : order
            ));
        } catch (error) {
            console.error('Failed to close order', error);
            return error;
        }
    };

    const deleteOrder = async (tableIdOrOrderId) => {
        try {
            await deleteOrderApi(eateryId, tableIdOrOrderId);
            setOrders(prevOrders => prevOrders.filter(order =>
                order.order.id !== tableIdOrOrderId && order.tableId !== tableIdOrOrderId
            ));
        } catch (error) {
            console.error('Error deleting order', error);
            return error;
        }
    };

    const createKey = (orderFoodItem) => {
        return `${orderFoodItem.id}`
            + `${orderFoodItem.selectedSize ? ('-' + orderFoodItem.selectedSize) : ''}`
            + `${orderFoodItem.selectedRequiredChoices ? '-' + Object.keys(orderFoodItem.selectedRequiredChoices).map(rc => orderFoodItem.selectedRequiredChoices[rc]).join('-') : ''}`
            + `${orderFoodItem.selectedExtras && Object.keys(orderFoodItem.selectedExtras) ? '-' + Object.keys(orderFoodItem.selectedExtras).join('-') : ''}`
            + `${orderFoodItem.additionalNote ? '-' + orderFoodItem.additionalNote : ''}`;
    };

    const addToOrder = async (orderId, newItem, itemKey, tableId, orderType = tableId ? 'IN_TABLE' : 'TAKE_OUT') => {
        // Calculate the item key based on its unique properties
        let hadItemKey = !!itemKey;
        if (!itemKey) {
            itemKey = newItem.key ? newItem.key : createKey(newItem);
        }

        let existingOrder = orderId ? getOrderById(orderId) : null;

        if (existingOrder) {
            const existingItemIndex = existingOrder.order.foodItems.findIndex(item => item.key === itemKey);
            let updatedOrder;

            if (existingItemIndex > -1) {
                // If the item exists, update the quantity
                if (!hadItemKey) newItem.quantity += existingOrder.order.foodItems[existingItemIndex].quantity;
                existingOrder.order.foodItems[existingItemIndex].quantity = newItem.quantity;
            } else {
                // If the item does not exist, add it to the order with a unique key
                let otherSizes;
                if (getFoodItemById(newItem.id).otherSizes) {
                    otherSizes = { [newItem.selectedSize]: getFoodItemById(newItem.id).otherSizes[newItem.selectedSize] };
                }

                existingOrder.order.foodItems.push({
                    key: itemKey, // Store the unique key
                    id: newItem.id,
                    quantity: newItem.quantity,
                    additionalNote: newItem.additionalNote || '',
                    otherSizes: otherSizes || {},
                    requiredChoices: newItem.selectedRequiredChoices || {},
                    extras: newItem.selectedExtras || {},
                    totalPrice: getFoodItemById(newItem.id).price + calculateAdditionalPrice(newItem),
                });
            }

            const cachedOrder = updateOrderApi(eateryId, existingOrder.table?.id || existingOrder.order.id, existingOrder.order);

            return cachedOrder;
        } else {
            console.log("note");
            // If the order does not exist, create a new one
            let otherSizes;
            if (getFoodItemById(newItem.id).otherSizes) {
                otherSizes = { [newItem.selectedSize]: getFoodItemById(newItem.id).otherSizes[newItem.selectedSize] };
            }

            let newOrder = {
                eateryId: eateryId,
                orderType: orderType,
                tableId: tableId,
                creator: 'ADMIN',
                people: [],
                foodItems: [
                    {
                        key: itemKey, // Store the unique key
                        id: newItem.id,
                        quantity: newItem.quantity,
                        additionalNote: newItem.notes || '',
                        otherSizes: otherSizes || {},
                        requiredChoices: newItem.selectedRequiredChoices || {}, // Default to empty object
                        extras: newItem.selectedExtras || {}, // Default to empty array
                        totalPrice: getFoodItemById(newItem.id).price + calculateAdditionalPrice(newItem),
                    }
                ]
            };

            const order = await createOrderApi(newOrder);
            return order;
        }
    };

    const removeFromOrder = async (orderId, itemKey) => {
        let existingOrder = getOrderById(orderId);

        if (existingOrder) {
            const updatedItems = existingOrder.order.foodItems.filter(item => item.key !== itemKey);

            const updatedOrder = {
                ...existingOrder.order,
                foodItems: updatedItems
            };

            const updatedCachedOrder = await updateOrderApi(eateryId, updatedOrder.id, updatedOrder);

            // Update orders state
            setOrders(prevOrders => prevOrders.map(order =>
                order.order.id === updatedCachedOrder.order.id ? updatedCachedOrder : order
            ));

            return updatedCachedOrder;
        }
    };

    // Updated helper function to calculate additional price
    const calculateAdditionalPrice = (item) => {
        let additionalPrice = 0;

        // Add price for size
        if (item.selectedSize) {
            const sizeOption = getFoodItemById(item.id).otherSizes[item.selectedSize];
            additionalPrice += sizeOption ? sizeOption : 0;
        }

        // Add prices for required choices
        Object.keys(item.selectedRequiredChoices || {}).forEach(choiceName => {
            if (choiceName !== 'size') {
                const choiceOption = getFoodItemById(item.id).requiredChoices[choiceName];
                const selectedOption = choiceOption ? choiceOption[choiceName] : null;
                additionalPrice += selectedOption ? selectedOption : 0;
            }
        });

        // Add prices for extras
        Object.keys(item.extras || {}).forEach(extra => {
            const extraPrice = getFoodItemById(item.id).extras[extra];
            additionalPrice += extraPrice ? extraPrice : 0;
        });

        return additionalPrice;
    };

    return (
        <OrdersContext.Provider value={{
            orders,
            setOrders,
            loading,
            getOrderById,
            getOrderByTableId,
            createOrder,
            validateOrder,
            openOrder,
            closeOrder,
            deleteOrder,
            addToOrder,
            removeFromOrder,
            createKey,
        }}>
            {children}
        </OrdersContext.Provider>
    );
};

export { OrdersContext, OrdersProvider };
