import { Box, Container, Button, Snackbar, Alert, Card, CardContent } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { OrdersContext } from '../../context/OrdersContext';
import '../../css/loader.css';
import { Topbar } from '../../shared-components/Topbar';
import AddTableButton from './components/AddTableButton';
import FilterChips from './components/FilterChips';
import TableCard from './components/TableCard';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable/dist';
import { CSS } from '@dnd-kit/utilities';
import theme from '../../theme';
import LongButton from '../../shared-components/LongButton';
import { AuthContext } from '../../context/AuthContext';

const SortableItem = ({ id, children, disabled, isExpanded }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled });

  const style = {
    transform: CSS.Transform.toString(transform) || 'translate3d(0, 0, 0)', // Ensuring default transform
    transition: transition || 'all 200ms ease', // Ensuring a smooth transition
    opacity: isDragging ? 0.5 : 1,
    cursor: disabled ? 'default' : 'grab',
    zIndex: isDragging ? 1000 : 'auto', // Make sure the dragging item is on top 
  };

  if (isExpanded) style.width = '100%';

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

const Tables = () => {
  const { user } = useContext(AuthContext);
  const { tables, createTable, reorderTables } = useContext(AppContext);
  const { orders } = useContext(OrdersContext);
  const [expandedTable, setExpandedTable] = useState(null);
  const [filteredStatuses, setFilteredStatuses] = useState({
    FREE: true,
    PENDING: true,
    OCCUPIED: true,
    TO_VALIDATE: true,
  });
  const [editMode, setEditMode] = useState(false);
  const [myTables, setMyTables] = useState([]);
  const [oldOrder, setOldOrder] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setMyTables(tables);
  }, [tables]);

  if (!tables || !myTables || !orders) {
    return <div className='loader'></div>;
  }

  const handleTableClick = async (tableNumber) => {
    if (!editMode) {
      try {
        const table = tables.find(t => t.name === tableNumber);
        if (!table) {
          createTable(tableNumber);
        } else {
          setExpandedTable((prev) => (prev === tableNumber ? null : tableNumber));
        }
      } catch (error) {
        console.error('Error handling table click:', error);
      }
    }
  };

  const handleAddTable = async (tableNumber) => {
    createTable(tableNumber);
  };

  const getTableStatus = (tableId) => {
    const tableOrder = orders.find(order => order.table?.id === tableId);

    if (tableOrder?.order?.status === 'CONFIRMED') {
      return 'TO_VALIDATE';
    } else if (tableOrder?.order?.status === 'VALIDATED') {
      return 'OCCUPIED';
    } else if (tableOrder?.order?.foodItems.length > 0) {
      return 'PENDING';
    }
    return 'FREE';
  };

  const handleFilterChange = (newFilters) => {
    setFilteredStatuses(newFilters);
  };

  const handleSetEditMode = () => {
    setExpandedTable(null);
    setEditMode((prev) => !prev)
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setOldOrder([...myTables]);  // Store the old order before updating
      setMyTables((prevTables) => {
        const oldIndex = prevTables.findIndex(table => table.name === active.id);
        const newIndex = prevTables.findIndex(table => table.name === over.id);

        return arrayMove(prevTables, oldIndex, newIndex);
      });
      setSnackbarOpen(true);
    }
  };

  const handleConfirmReorder = () => {
    reorderTables(myTables.map(t => t.id));
    setEditMode(false);
    setSnackbarOpen(false);
  };

  const handleCancelReorder = () => {
    setMyTables(oldOrder);
    setSnackbarOpen(false);
  };

  const filteredTables = myTables.filter(table => filteredStatuses[getTableStatus(table.id)]);

  return (
    <Container maxWidth="sm" style={{ padding: '16px', fontFamily: '"Inter", sans-serif' }}>
      <Topbar name="Mes tables" />
      {myTables.length === 0 &&
        <Box sx={{
          background: theme.palette.secondaryContainer.main
        }}>
          <CardContent sx={{ textAlign: 'center' }}>
            Vous n'avez encore créé aucune table.
          </CardContent>
        </Box>
      }
      {!editMode && !(myTables.length === 0) && <FilterChips onFilterChange={handleFilterChange} />}
      {editMode &&
        <Box sx={{
          background: theme.palette.secondaryContainer.main
        }}>
          <CardContent>
            Vous êtes en mode édition.
            Vous pouvez maintenant modifier la disposition des tables en les réordonnant.
          </CardContent>
        </Box>
      }

      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={filteredTables.map(table => table.name)} strategy={verticalListSortingStrategy}>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            gap="10px"
            width="100%"
            height={'100%'}
            paddingY={2}
            marginY={5}
            sx={{
              background: editMode ? theme.palette.container.primary.highest : 'inherit',
              border: editMode ? '5px dashed ' + theme.palette.primary.main : 'none',
              borderRadius: editMode ? '12px' : '0',
              //borderColor: editMode ? theme.prale : 'inherit',

            }}
          >
            {filteredTables.map((table) => (
              <SortableItem
                key={table.name}
                id={table.name}
                disabled={!editMode} // disable dragging if editMode is off
                isExpanded={expandedTable === table.name}
              >
                <TableCard
                  table={table}
                  order={orders.find(order => order.table?.id === table.id)}
                  isExpanded={expandedTable === table.name}
                  tableStatus={getTableStatus(table.id)}
                  onClick={() => handleTableClick(table.name)}
                // style={{ pointerEvents: editMode ? 'none' : 'auto' }}
                />
              </SortableItem>
            ))}
            {user.role === "ADMIN" && !editMode && <AddTableButton onAddTable={handleAddTable} />}
          </Box>
        </SortableContext>
      </DndContext>
      {user.role === "ADMIN" && myTables.length > 0 &&
        <Box mx={6}>
          <LongButton
            variant="outlined"
            label={editMode ? 'Désactiver le mode édition' : 'Réordonner les tables'}
            onClick={handleSetEditMode}
            style={{ marginBottom: '16px' }}
          />
        </Box>
      }

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCancelReorder}
        message="Confirmer la nouvelle disposition des tables?"
        action={
          <Box>
            <Button color="inherit" size="small" onClick={handleCancelReorder}>
              Annuler
            </Button>
            <Button color="secondary" size="small" onClick={handleConfirmReorder}>
              Confirmer
            </Button>
          </Box>
        }
      />
    </Container >
  );
};

export default Tables;
