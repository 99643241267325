import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import tableOverviewImage from '../../../assets/images/table-overview-3.png';

const TablesBox = () => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="center"
            // height={142}
            // width={142}
            width="100%"
            border={"1px solid"}
            borderRadius={8}
            borderColor={theme.palette.outline.main}
            bgcolor={theme.palette.secondaryContainer.main}
            boxSizing="border-box"
            gap={2}
            paddingY={2}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
                flexGrow={1}
                paddingY="30px"
                paddingLeft="30px"
                paddingRight="-30px"
                width={'100%'}
            >
                <Typography
                    variant="subtitle2"
                    sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '24px',
                        lineHeight: '140%',
                        color: theme.palette.text.primary,
                    }}
                >
                    Vue d'ensemble
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '24px',
                        lineHeight: '140%',
                        color: theme.palette.text.primary,
                    }}
                >
                    du restaurant
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="right"
                justifyContent="flex-end"
                marginTop="auto"
                width={'100%'}
            >
                <Box component={'img'} src={tableOverviewImage} height={160} />
            </Box>
        </Box>
    );
};

export default TablesBox;
