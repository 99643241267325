import React, { useState, useContext } from 'react';
import {
    Card, Typography, IconButton, Box, RadioGroup, FormControlLabel, Radio, Checkbox, TextField,
} from '@mui/material';
import { AddCircleOutlineOutlined, RemoveCircleOutlineRounded } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import LongButton from '../../../shared-components/LongButton';
import theme from '../../../theme';
import QuantitySelector from '../../../shared-components/QuantitySelector';
import { OrdersContext } from '../../../context/OrdersContext';

const TakeOrderFoodItemCard = ({ id, name, price, currency, otherSizes, requiredChoices, extras, initialQuantity, order, onUpdate }) => {
    const [expanded, setExpanded] = useState(false);
    const [quantity, setQuantity] = useState(initialQuantity);
    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedRequiredChoices, setSelectedRequiredChoices] = useState({});
    const [selectedExtras, setSelectedExtras] = useState({});
    const [notes, setNotes] = useState('');
    const { addToOrder, createKey } = useContext(OrdersContext);

    const handleToggleExpand = () => {
        if ((!otherSizes || otherSizes.length === 0)
            && (!requiredChoices || requiredChoices.length === 0)
            && (!extras || Object.keys(extras).length === 0)) {
            handleAddToOrder();
        } else {
            setExpanded(!expanded);
        }
        //setExpanded(!expanded);
    };


    const handleOptionChange = (optionName, value) => {
        setSelectedRequiredChoices({ ...selectedRequiredChoices, [optionName]: value });
    };

    const handleExtraChange = (extra, value) => {
        setSelectedExtras({ ...selectedExtras, [extra.name]: extra.price });
    };

    const handleAddToOrder = async () => {
        console.log("handleAddToOrder quantity=", quantity, name);
        const newItem = {
            id,
            name,
            quantity,
            selectedSize,
            selectedRequiredChoices,
            selectedExtras,
            additionalNote: notes,
            price,
            currency,
        };

        const r = await addToOrder(order?.order.id, newItem);
        onUpdate(r);
        setExpanded(false);
    };

    return (
        <Card sx={{
            padding: 2,
            marginBottom: 2,
            width: '100%',
            border: 1,
            borderRadius: 4,
            borderColor: theme.palette.outline.main
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">{name}</Typography><Typography variant="body2">- {price}</Typography>
                <motion.div
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                    <IconButton onClick={handleToggleExpand} size='large'>
                        {expanded ? <RemoveCircleOutlineRounded sx={{ width: '32px', height: '32px' }} /> : <AddCircleOutlineOutlined sx={{ width: '32px', height: '32px' }} />}
                    </IconButton>
                </motion.div>
            </Box>
            <AnimatePresence initial={false}>
                {expanded && (
                    <motion.div
                        key="content"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Box sx={{ marginTop: 2 }}>
                            {otherSizes && otherSizes.length > 0 && (
                                <Box sx={{ marginTop: 2 }}>
                                    <Typography variant="body1" fontWeight={500}>Autres tailles</Typography>
                                    {otherSizes.map((size, index) => (
                                        <FormControlLabel
                                            key={size.name}
                                            control={
                                                <Checkbox
                                                    checked={selectedSize === size.name}
                                                    onChange={() => setSelectedSize((selectedSize === size.name) ? null : size.name)} // Ensures only one is selected
                                                />
                                            }
                                            label={`${size.name}` + (size.price > 0 ? ` (+${size.price} ${currency})` : '')}
                                        />
                                    ))}
                                </Box>
                            )}
                            {requiredChoices && requiredChoices.length > 0 && (
                                <Box sx={{ marginTop: 2 }}>
                                    <Typography variant="body1" fontWeight={500}>Choix obligatoires</Typography>
                                    {requiredChoices.map((choice) => (
                                        <Box key={choice.name} sx={{ marginBottom: 2 }}>
                                            <Typography variant="body2">{choice.name}</Typography>
                                            <RadioGroup
                                                value={selectedRequiredChoices[choice.name] || ''}
                                                onChange={(e) => handleOptionChange(choice.name, e.target.value)}
                                            >
                                                {choice.options.map((option) => (
                                                    <FormControlLabel
                                                        key={option.choice}
                                                        value={option.choice}
                                                        control={<Radio />}
                                                        label={`${option.choice} (+${option.price}${currency})`}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                            {extras && extras.length > 0 && (
                                <Box sx={{ marginTop: 2 }}>
                                    <Typography variant="body1" fontWeight={500} mb={1}>Extras</Typography>
                                    {extras.map((extra) => {
                                        return (
                                            <Box key={extra.name} sx={{ marginBottom: 1 }}>
                                                <FormControlLabel
                                                    key={extra}
                                                    control={<Checkbox
                                                        checked={!!selectedExtras[extra.name]}
                                                        onChange={(e) => handleExtraChange(extra, e.target.value)}
                                                    />}
                                                    label={`${extra.name} (+${extra.price}${currency})`}
                                                />
                                            </Box>
                                        )
                                    })}
                                </Box>
                            )}
                            <TextField
                                label="Note additionnelle"
                                multiline
                                fullWidth
                                rows={4}
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                variant="outlined"
                                margin="normal"
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2, gap: 2 }}>
                                <QuantitySelector initialQuantity={quantity} onChange={setQuantity} />
                                <LongButton variant="primary" onClick={handleAddToOrder} label={"Ajouter"} />
                            </Box>
                        </Box>
                    </motion.div>
                )}
            </AnimatePresence>
        </Card>
    );
};

export default TakeOrderFoodItemCard;
