import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, "Helvetica Neue", Arial, sans-serif',
    body: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
    },
    "body-small": {
      fontfamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
    body2: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle3: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    labelLarge: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '28px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    additionalNotes: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#49454F',
    },
  },
  palette: {
    background: {
      default: '#FEF7FF',
      paper: '#FFFFFF',
    },
    container: {
      primary: {
        highest: '#E6E0E9',
        lowest: '#F7F2FA',
      }
    },
    primary: {
      main: '#65558F',
      light: '#E0E0F8',
      dark: '#C0C0F0',
      contrastText: '#FFFFFF',
      inverse: '#D0BCFF',
    },
    secondary: {
      main: '#E8DEF8',
      text: '#49454F', // Added secondary text color
    },
    error: {
      main: '#B3261E',
      light: '#E8A0A0',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#1D1B20',
      secondary: '#49454F',
    },
    surface: {
      container: '#FEF7FF',
    },
    secondaryContainer: {
      main: '#E8DEF8',
    },
    outline: {
      main: '#79747E'
    }
  },
});

export default theme;
