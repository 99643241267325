import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Outlet, useLocation, useRoutes } from "react-router-dom";
import { ErrorProvider } from './ErrorContext';
import ProtectedRoute from './ProtectedRoute';
import SignIn from './SignIn';
import { AppContextProvider } from './context/AppContext';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { CategoryProvider } from './context/CategoryContext';
import { OrdersProvider } from './context/OrdersContext';
import './index.css';
import MainLayout from './shared-components/MainLayout';
import theme from './theme';
import Categories from './views/categories/Categories';
import Customize from './views/customize/Customize';
import Dashboard3 from './views/dashboard/Dashboard3';
import CategoryDetails from './views/editcategory/CategoryDetails';
import FoodItemBuilder from './views/fooditembuilder/FoodItemBuilder';
import Orders2 from './views/orders/Orders2';
import ArchivedOrders from './views/orders/screens/ArchivedOrders';
import SocialMediaLinks from './views/socialmedialinks/SocialMediaLinks';
import Tables from './views/tables/Tables';
import TakeOrder from './views/take-order/TakeOrder';
import Ratings from './views/ratings/ratings';
import OutOfStockItems from './views/outofstock/OutOfStockItems'

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

const AuthenticatedApp = ({ user }) => {
    const location = useLocation();

    const authenticatedRoutes = useRoutes([
        {
            path: "/",
            element: (
                <ProtectedRoute>
                    <MainLayout>
                        <Outlet />
                    </MainLayout>
                </ProtectedRoute>
            ),
            children: [
                { path: "", element: <Dashboard3 /> },
                { path: "orders", element: <OrdersProvider><Orders2 /></OrdersProvider> },
                { path: "orders/edit", element: <OrdersProvider><TakeOrder /></OrdersProvider> },
                { path: "orders/archived", element: <OrdersProvider><ArchivedOrders /></OrdersProvider> },
                { path: "out-of-stock", element: <OutOfStockItems /> },
                { path: "tables", element: <OrdersProvider><Tables /></OrdersProvider> },
                { path: "categories", element: <Categories /> },
                { path: "menu", element: <CategoryDetails /> },
                { path: "menu/item/create", element: <FoodItemBuilder update={false} /> },
                { path: "menu/item/:id", element: <FoodItemBuilder update={true} /> },
                { path: "customize", element: <Customize /> },
                { path: "socials", element: <SocialMediaLinks /> },
                { path: "ratings", element: <Ratings /> },
            ],
        },
    ]);

    return (
        <AppContextProvider eateryId={user.eateryId}>
            <CategoryProvider>
                {authenticatedRoutes}
            </CategoryProvider>
        </AppContextProvider>
    );
};

const UnauthenticatedApp = () => {
    const unauthenticatedRoutes = useRoutes([
        { path: "/", element: <SignIn /> },
        { path: "*", element: <Navigate to="/" replace /> },
    ]);

    return unauthenticatedRoutes;
};

const App = () => {
    const { user, isAuthenticated } = React.useContext(AuthContext);

    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            {isAuthenticated ? <AuthenticatedApp user={user} /> : <UnauthenticatedApp />}
        </React.Suspense>
    );
};

root.render(
    <ThemeProvider theme={theme}>
        <ErrorProvider>
            <BrowserRouter>
                <AuthProvider>
                    <CssBaseline />
                    <App />
                </AuthProvider>
            </BrowserRouter>
        </ErrorProvider>
    </ThemeProvider>,
);
