import React, { createContext, useCallback, useEffect, useState } from 'react';
import useApi from '../api';

const AppContext = createContext();

const AppContextProvider = ({ children, eateryId }) => {
  const {
    apiCall,
    getMenuApi,
    setMenuApi: setCategoryApi,
    createOrUpdateFoodItem,
    deleteFoodItem,
    deleteFoodItemPhoto,
    setOutOfStockApi,
    createTableApi,
    getTablesApi,
    reorderTablesApi,
    editTableNameApi,
    deleteTableApi,
    setSocialMediaApi
  } = useApi();
  const [data, setData] = useState(null);
  const [menu, setMenu] = useState([]);
  const [tables, setTables] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await apiCall({ url: `/api/eatery/menu/${eateryId}`, method: 'GET' });
      // // Cache-busting: Append a timestamp to each image URL in the menu
      // const cacheBustedMenu = Object.entries(result.menu).reduce((acc, [categoryId, items]) => {
      //   acc[categoryId] = items.map(item => ({
      //     ...item,
      //     photo: {
      //       original: {
      //         jpg: `${item.photo.original.jpg}?t=${new Date().getTime()}`,
      //         webp: `${item.photo.original.webp}?t=${new Date().getTime()}`,
      //       },
      //       thumbnail: {
      //         jpg: `${item.photo.thumbnail.jpg}?t=${new Date().getTime()}`,
      //         webp: `${item.photo.thumbnail.webp}?t=${new Date().getTime()}`,
      //       }
      //     }
      //   }));
      //   return acc;
      // }, {});

      // console.log("cacheBustedMenu", cacheBustedMenu);
      // const cacheBustedResult = {
      //   ...result,
      //   menu: cacheBustedMenu
      // };

      // setData(cacheBustedResult);
      setData(result);
      setMenu(result.menu);
      setTables(result.tables);
      setCurrency(result.eatery.currency);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [apiCall, eateryId]);

  useEffect(() => {
    fetchData();
  }, []);

  const getMenu = async () => {
    const result = await getMenuApi();
    setMenu(result);
    return result;
  }

  const handleCreateOrUpdateFoodItem = async (values, update, foodItemId, photo) => {
    try {
      await createOrUpdateFoodItem(values, update, foodItemId, photo);
      const newMenu = await getMenuApi();
      setMenu(newMenu);
    } catch (error) {
      throw error;
    }
  };

  const handleDeleteFoodItem = async (foodItemData) => {
    try {
      await deleteFoodItem(foodItemData);
      const newMenu = await getMenuApi();
      setMenu(newMenu);
    } catch (error) {
      throw error;
    }
  };

  const handleDeleteFoodItemPhoto = async (foodItem) => {
    await deleteFoodItemPhoto(foodItem.id);
    const foodItemIndex = data.menu[foodItem.category].findIndex((e) => e.id === foodItem.id);
    data.menu[foodItem.category][foodItemIndex].photo = null;
    data.menu[foodItem.category][foodItemIndex].thumbnail = null;
    const newMenu = await getMenuApi();
    setMenu(newMenu);
  };

  const handleChangeLogo = async (newLogo) => {
    await handleChangeLogoApi(newLogo);
  };

  const handleChangeBanner = async (newBanner) => {
    await handleChangeBannerApi(newBanner);
  };

  const setSocialMedia = async (socialNetworkName, link) => {
    await setSocialMediaApi(socialNetworkName, link);
    data.eatery.links[socialNetworkName] = link;
  };


  const setOutOfStock = async (foodItemId, bool) => {
    try {
      await setOutOfStockApi(foodItemId, bool);
      const newMenu = await getMenuApi();
      setMenu(newMenu);
    } catch (error) {
      console.error('Error setting out of stock:', error);
    }
  };

  const createTable = async (tableNumber) => {
    try {
      await createTableApi(tableNumber);
      const updatedTables = await getTables();
      setTables(updatedTables);
    } catch (error) {
      console.error('Error adding table:', error);
    }
  };

  const getTables = async () => {
    const response = await getTablesApi();
    setTables(response);
    return response;
  };

  const reorderTables = async (tableIds) => {
    const response = await reorderTablesApi(tableIds);
    setTables(response);
    return response;
  };

  const editTableName = async (tableId, tableName) => {
    const response = await editTableNameApi(tableId, tableName);
    setTables(tables.map(table =>
      table.id === tableId ? { ...table, name: tableName } : table
    ));
    return response;
  };

  const deleteTable = async (tableId) => {
    await deleteTableApi(tableId);
    setTables(tables.filter(t => t.id !== tableId));
  }

  const setCategory = async (category) => {
    await setCategoryApi(category);
    const newMenu = await getMenuApi();
    console.log("newmenu", newMenu);
    setMenu(newMenu);
  };

  return (
    <AppContext.Provider value={{
      data, eateryId, currency, menu, tables, loading, error,
      fetchData,
      getMenu,
      handleCreateOrUpdateFoodItem,
      handleDeleteFoodItem,
      handleDeleteFoodItemPhoto,
      setOutOfStock,
      handleChangeLogo,
      handleChangeBanner,
      setSocialMedia,
      createTable,
      getTables,
      reorderTables,
      editTableName,
      deleteTable,
      setCategory,
    }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };

