import React from 'react';
import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const getStatusChipColor = (status) => {
    switch (status) {
        case 'CREATED':
            return 'default';
        case 'CONFIRMED':
            return 'primary';
        case 'VALIDATED':
            return 'success';
        case 'CLOSED':
            return 'error';
        default:
            return 'default';
    }
};

const translateOrderStatus = (status) => {
    switch (status) {
        case 'CREATED':
            return 'OUVERT';
        case 'CONFIRMED':
            return 'CONFIRMÉ';
        case 'VALIDATED':
            return 'VALIDÉ';
        case 'CLOSED':
            return 'FERMÉ';
        default:
            return 'INCONNU';
    }
};

const OrderStatusChip = ({ status }) => {
    const theme = useTheme();
    const color = getStatusChipColor(status);
    const label = translateOrderStatus(status);

    return (
        <Chip
            label={label}
            color={color}
            size="small"
            sx={{
                backgroundColor: theme.palette[color]?.main || theme.palette.grey[300],
                color: theme.palette[color]?.contrastText || theme.palette.text.primary,
            }}
        />
    );
};

export default OrderStatusChip;
