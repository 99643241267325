import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import logo from "./assets/images/logo-complet-transparent.svg";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import theme from './theme'
import LongButton from './shared-components/LongButton'

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.welcome.forknife.app/">
                Forknife
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

export default function SignIn() {
    const theme = useTheme();
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');

        try {
            await login(email, password);
            navigate('/');
        } catch (error) {
            setErrorMessage("Nous n'avons pas pu vous authentifier." +
                " Veuillez réessayer. Si le problème persiste, contacter l'administrateur.");
            console.error('Login failed', error);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img
                        srcSet={logo}
                        src={logo}
                        height={theme.spacing(10)}
                        alt="logo"
                    />
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, mx: 2 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Adresse mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <LongButton
                            type="submit"
                            variant="contained"
                            label="Se connecter"
                            sx={{ width: '100%', mt: 3, mb: 2 }}
                        >
                            {errorMessage && (
                                <Typography color="error" sx={{ mb: 2 }}>
                                    {errorMessage}
                                </Typography>
                            )}
                        </LongButton>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
