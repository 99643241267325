import { Close } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyIcon from '@mui/icons-material/Key';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Alert, Box, Button, Drawer, IconButton, List, ListItem, Snackbar, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion'; // Import framer-motion
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { OrdersContext } from '../../../context/OrdersContext';
import '../../../css/loader.css';
import useMenu from '../../../hooks/useMenu';
import LongButton from '../../../shared-components/LongButton';
import QuantitySelector from '../../../shared-components/QuantitySelector';
import { Navigate, useNavigate } from 'react-router-dom';
import OrderStatusChip from '../../../shared-components/OrderStatusChip';

const stopPropagationWrapper = (fn) => (event) => {
    event.stopPropagation();
    fn(event);
};

const OrderDrawer = ({ order, drawerOpen, toggleDrawer, onUpdate }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { currency } = useContext(AppContext);
    const { getFoodItemById } = useMenu();
    const { orders, addToOrder, removeFromOrder, validateOrder, openOrder, closeOrder, deleteOrder } = useContext(OrdersContext);
    const [newItemAdded, setNewItemAdded] = useState(false);
    const [groupedFoodItems, setGroupedFoodItems] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };


    const groupFoodItems = (foodItems) => {
        const groupedItems = {};

        foodItems.forEach((item) => {
            let itemKey = item.key || `${item.id}`
                + `${item.otherSizes ? '-' + Object.keys(item.otherSizes).join('-') : ''}`
                + `${item.requiredChoices ? '-' + Object.keys(item.requiredChoices).map(rc => item.requiredChoices[rc]).join('-') : ''}`
                + `${item.extras ? '-' + Object.keys(item.extras).join('-') : ''}`
                + `${item.additionalNote ? '-' + item.additionalNote : ''}`;

            if (groupedItems[itemKey]) {
                groupedItems[itemKey].quantity += item.quantity;
            } else {
                groupedItems[itemKey] = item;
            }
        });

        return Object.values(groupedItems);
    };

    const handleQuantityChange = async (item, newQuantity) => {
        let updatedOrder;
        if (newQuantity > 0) {
            const updatedItem = {
                ...item,
                quantity: newQuantity,
            };
            updatedOrder = await addToOrder(order.order.id, updatedItem, item.key, order.order.tableId, order.order.orderType);
        } else {
            updatedOrder = await removeFromOrder(order.order.id, item.key);
        }

        onUpdate(updatedOrder);
    };

    const handleValidateOrder = async () => {
        try {
            await validateOrder(order.table?.id || order.order.id);
            showSnackbar('Commande validée avec succès.');
        } catch (error) {
            showSnackbar('Échec de la validation de la commande.', 'error');
        }
    };

    const handleOpenOrder = async () => {
        const error = await openOrder(order.table?.id || order.order.id);
        if (!error) {
            showSnackbar('Commande réouverte avec succès.');
        } else {
            showSnackbar('Échec de la réouverture de la commande.', 'error');
        }
    };

    const handleCloseOrder = async () => {
        const error = await closeOrder(order.table?.id || order.order.id);
        if (!error) {
            showSnackbar('Commande fermée.');
            toggleDrawer(false);
            setTimeout(() => {
                navigate('/orders');
            }, 2500);
        } else {
            showSnackbar('Échec de la libération de la table.', 'error');
        }
    };

    const handleDelete = async () => {
        try {
            await deleteOrder(order.order.id);
            showSnackbar('Commande supprimée avec succès.');
            setTimeout(() => {
                navigate('/orders');
            }, 2500);
        } catch (error) {
            showSnackbar('Échec de la suppression de la commande.', 'error');
        }
    };


    // Trigger the animation when a new item is added
    useEffect(() => {
        setNewItemAdded(true);
        const timeout = setTimeout(() => setNewItemAdded(false), 300); // Reset after the animation duration
        return () => clearTimeout(timeout);
    }, [orders]);

    // Define the pulse animation
    const pulseAnimation = {
        scale: newItemAdded ? [1, 1.1, 1] : 1, // Scale up and then back to normal
        transition: { duration: 0.3 },
    };

    useEffect(() => {
        const grouped = groupFoodItems(order?.order.foodItems || []);
        setGroupedFoodItems(grouped);
    }, [order]);

    if (!order || !groupedFoodItems) {
        return <div className='loader'></div>
    }

    return (
        <>
            <motion.div
                style={{
                    position: 'fixed',
                    bottom: "80px",
                    width: '100%',
                    backgroundColor: theme.palette.primary.main,
                    padding: 2,
                    borderTopLeftRadius: '96px',
                    borderTopRightRadius: '96px',
                    boxShadow: '0 -8px 16px rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
                onClick={toggleDrawer(true)}
                animate={pulseAnimation} // Apply the pulse animation
            >
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                    Commande ({groupedFoodItems.reduce((total, item) => total + item.quantity, 0) || "vide"})
                </Typography>
            </motion.div>
            <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: theme.palette.primary.main,
                        paddingY: 2,
                        borderTop: '1px solid',
                        borderTopLeftRadius: '24px',
                        borderTopRightRadius: '24px',
                    },
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', paddingY: 2, paddingX: 3, height: '100%' }}>
                    <IconButton
                        onClick={toggleDrawer(false)}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: theme.palette.primary.contrastText
                        }}
                    >
                        <Close />
                    </IconButton>
                    <Box sx={{
                        marginY: 2,
                        padding: 2,
                        width: '100%',
                        backgroundColor: theme.palette.background.paper,
                        border: '1px solid transparent',
                        borderRadius: '12px',
                    }}>
                        <Typography variant="h6">Bon de commande <OrderStatusChip status={order.order.status} /></Typography>
                        {groupedFoodItems.length > 0 ? (
                            <>
                                <List sx={{ width: '100%' }}>
                                    {groupedFoodItems.map((item, index) => {
                                        console.log("item", item)
                                        return (
                                            <ListItem key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '10px', borderBottom: '1px solid #CAC4D0' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '65%' }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                                                            <Typography variant="body1" sx={{ fontWeight: 800 }}>{`${item.quantity}`}</Typography>
                                                            <Typography variant="body1" sx={{ fontWeight: 400 }}>{`x ${getFoodItemById(item.id).name}`}</Typography>
                                                        </Box>
                                                        <Typography variant="additionalNotes">{`${item.totalPrice.toFixed(2)} ${currency}`}</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'right', width: '35%' }}>
                                                        <QuantitySelector initialQuantity={item.quantity} onChange={(newQuantity) => { handleQuantityChange(item, newQuantity) }} />
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingY: '8px' }}>
                                                    {item.requiredChoices && Object.keys(item.requiredChoices).map((option, idx) => (
                                                        <Typography key={idx} variant="body2" sx={{ color: '#49454F' }}>{`• ${option}: ${item.requiredChoices[option]}`}</Typography>
                                                    ))}
                                                    {item.extras && Object.keys(item.extras).map((extra, idx) => (
                                                        <Typography key={idx} variant="body2" sx={{ color: '#49454F' }}>{`+ ${extra} (${item.extras[extra]}${currency})`}</Typography>
                                                    ))}
                                                    {item.additionalNote && <Typography variant="body2" sx={{ color: '#49454F' }}>{`- Note: ${item.additionalNote}`}</Typography>}
                                                </Box>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                                <Typography variant="h6" align="right" sx={{ paddingY: '16px' }}>
                                    Sous-total: {(groupedFoodItems.reduce((sum, item) => sum + item.totalPrice, 0))?.toFixed(2)} {currency}
                                </Typography>
                                <Box display="flex" flexDirection="column" mb={2} width="100%" gap={2}>
                                    {order.order.status === 'CREATED' && (
                                        <>
                                            <LongButton variant="primary" icon={CheckCircleOutlineIcon} label="Valider la commande" onClick={handleValidateOrder} />
                                            <LongButton variant="disabled" icon={LockOpenIcon} label="Le client peut modifier la commande" />
                                            <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={handleDelete} />
                                        </>
                                    )}
                                    {order.order.status === 'CONFIRMED' && (
                                        <>
                                            <LongButton variant="primary" icon={CheckCircleOutlineIcon} label="Valider la commande" onClick={handleValidateOrder} />
                                            <LongButton variant="primary" icon={LockOpenIcon} label="Permettre modification client" onClick={handleOpenOrder} />
                                            <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={handleDelete} />
                                        </>
                                    )}
                                    {order.order.status === 'VALIDATED' && (
                                        <>
                                            <LongButton variant="primary" icon={KeyIcon} label={order.table ? "Libérer la table" : "Archiver la commande"} onClick={handleCloseOrder} />
                                            <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={handleDelete} />
                                        </>
                                    )}
                                    {order.order.status === 'CLOSED' && (
                                        <>
                                            <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={handleDelete} />
                                        </>
                                    )}
                                </Box>
                            </>
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingY: 5 }}>
                                <Typography variant="body1" color="textSecondary">La commande est vide.</Typography>
                                <Button variant="contained" color="primary" onClick={toggleDrawer(false)} sx={{ marginTop: 2 }}>
                                    Fermer
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Drawer>
        </>
    );
};

export default OrderDrawer;
