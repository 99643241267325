import React, { useContext, useEffect } from 'react'
import { Topbar } from '../../shared-components/Topbar'
import CategoriesDraggableList from './components/CategoriesDraggableList'
import { AppContext } from '../../context/AppContext';
import '../../css/loader.css'
import { Box, Container, Typography } from '@mui/material';
import { CategoryContext } from '../../context/CategoryContext';

const Categories = () => {
  const { data, loading, error, menu } = useContext(AppContext);
  const { categories } = useContext(CategoryContext);

  if (loading || !data) {
    return <div className="loader"></div>
  };

  return (
    <Container maxWidth="sm" style={{ padding: '16px', fontFamily: '"Inter", sans-serif' }}>
      <Topbar name="Mes catégories" />
      <Box display={'flex'} width={"100%"} my={3}>
        <Box display={'flex'} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} flexGrow={1}>
          <Typography variant='h5' fontWeight={'bolder'}>{categories.length}</Typography>
          <Typography variant='body1' fontWeight={'bold'}>catégories</Typography>
        </Box>
        <Box display={'flex'} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} flexGrow={1}>
          <Typography variant='h5' fontWeight={'bolder'}>{Object.values(menu).flat().length}</Typography>
          <Typography variant='body1' fontWeight={'bold'}>articles</Typography>
        </Box>
        <Box display={'flex'} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} flexGrow={1}>
          <Typography variant='h5' fontWeight={'bolder'}>{Object.values(menu).flat().filter(item => item.outOfStock).length}</Typography>
          <Typography variant='body1' fontWeight={'bold'}>ruptures</Typography>
        </Box>
      </Box>
      <CategoriesDraggableList />
    </Container>
  )
}




export default Categories