import { Box, CardContent, Container, Divider, List, ListItem } from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { CategoryContext } from '../../context/CategoryContext';
import '../../css/loader.css';
import useMenu from '../../hooks/useMenu';
import { Topbar } from '../../shared-components/Topbar';
import theme from "../../theme";
import OrderDrawer from "./components/OrderDrawer";
import TakeOrderFoodItemCard from "./components/TakeOrderFoodItemCard";
import CategoriesNav from '../editcategory/components/CategoriesNav';
import { AppContext } from '../../context/AppContext';
import { OrdersContext } from '../../context/OrdersContext';

const TakeOrder = () => {
    const { menu, getFoodItemById } = useMenu();
    const { currency } = useContext(AppContext);
    const { selectedCategory } = useContext(CategoryContext);
    const { orders, createOrder } = useContext(OrdersContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();
    const [currentOrder, setCurrentOrder] = useState(location.state?.currentOrder);
    const isNewTakeoutOrder = location.state?.currentOrder == null;
    const navigate = useNavigate();
    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    useEffect(() => {
        console.log("looking for current order in ", orders, currentOrder);
        const newCurrentOrder = orders.find(o => o.id === currentOrder?.order.id)
        if (newCurrentOrder != null) {
            setCurrentOrder(newCurrentOrder)
        } else {
            //navigate('/orders');
        }
    }, [orders]);

    useEffect(() => {
        const initializeOrder = async () => {
            const newOrder = await createOrder('TAKE_OUT');
            setCurrentOrder(newOrder);
        };
        if (!currentOrder) {
            console.log("initializing new order");
            initializeOrder();
        }
    }, [currentOrder]);



    if (currentOrder === undefined) {
        console.log("currentOrder is undefined, nqvigating to tables...");
        navigate("/tables");
    }

    if (!selectedCategory || !menu || !currency) {
        return <div className="loader"></div>;
    }

    return (
        <Container
            disableGutters
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: theme.palette.surface.container,
                paddingY: '16px',
                width: '100%',
                //height: '100vh',
                mb: 5,
                pb: 5
            }}
        >
            <Topbar name={"Bon de commande"} subtitle={(currentOrder?.table ? "à table" : "à emporter")} showBackButton />
            <CategoriesNav />
            <List disablePadding sx={{ width: '100%' }}>
                {menu[selectedCategory.id].filter(item => !item.outOfStock).map((foodItem) => (
                    <ListItem key={foodItem.id}>
                        <TakeOrderFoodItemCard
                            id={foodItem.id}
                            name={foodItem.name}
                            price={foodItem.price}
                            currency={currency}
                            initialQuantity={1}
                            otherSizes={foodItem.otherSizes ? Object.entries(foodItem.otherSizes).map(([name, price]) => ({ name, price })) : []}
                            requiredChoices={foodItem.requiredChoices ? Object.entries(foodItem.requiredChoices).map(([choiceName, options]) => ({
                                name: choiceName,
                                options: Object.entries(options).map(([optionName, price]) => ({
                                    choice: optionName,
                                    price: price
                                }))
                            })) : []}
                            extras={foodItem.extras ? Object.entries(foodItem.extras).map(([name, price]) => ({ name, price })) : []}
                            order={currentOrder}
                            isNewTakeoutOrder={isNewTakeoutOrder}
                            onUpdate={setCurrentOrder}
                        />
                        <Divider />
                    </ListItem>
                ))}
            </List>

            {menu[selectedCategory.id].length == 0 && (
                <Box sx={{
                    background: theme.palette.secondaryContainer.main,
                    width: '100%'
                }} display={'flex'} justifyContent={'center'} alignItems={'center'} my={3}>
                    <CardContent sx={{ textAlign: 'center' }}>
                        Cette catégorie ne contient aucun article.
                    </CardContent>
                </Box>
            )}
            <OrderDrawer order={currentOrder} drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} onUpdate={setCurrentOrder} />
        </Container>
    );
};

export default TakeOrder;
